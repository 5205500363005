<template>
  <a-modal
    v-model:visible="visible"
    centered
    :title="fileName"
    @ok="handleCancel"
    @cancel="handleCancel"
    width="90%"
    wrapClassName="preview-full-modal"
  >
    <div v-if="status == 1">
      <videoPreview
        :filePath="path"
        :openViewWatermark="openViewWatermark"
        v-if="type == 1"
      />
      <audioPreview
        :filePath="path"
        :openViewWatermark="openViewWatermark"
        v-else-if="type == 2"
      />
      <pdfPreview
        :filePath="path"
        :openViewWatermark="openViewWatermark"
        v-else-if="type == 3"
      />
      <imgPreview
        :filePath="path"
        :openViewWatermark="openViewWatermark"
        v-else-if="type == 4"
      />
      <htmlPreview
        :filePath="path"
        :openViewWatermark="openViewWatermark"
        v-else-if="type == 6"
      />
    </div>
    <div v-else-if="status == 11 || status == 12">
      <loadPreview />
    </div>
    <div v-else>
      <errorPreview />
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import videoPreview from "./video.vue";
import audioPreview from "./audio.vue";
import pdfPreview from "./pdf.vue";
import imgPreview from "./img.vue";
import htmlPreview from "./html.vue";
import loadPreview from "./load.vue";
import errorPreview from "./error.vue";

export default defineComponent({
  components: {
    videoPreview,
    audioPreview,
    pdfPreview,
    imgPreview,
    htmlPreview,
    loadPreview,
    errorPreview,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    openViewWatermark: {
      type: Boolean,
      default: false,
    },
    fileStatus: {
      type: Number,
      default: 1,
    },
    documentId: {
      type: Number,
      default: 0,
    },
    fileType: {
      required: true,
    },
    filePath: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      default: "素材详情",
    },
    fileHome: {
      type: String,
      default: "index.html",
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      viewType: "dialog",
      visible: false,
      status: 1,
      type: "",
      path: "",
    });

    watch(
      () => props.isVisible,
      (newVal) => {
        state.visible = newVal;
        if (newVal) {
          state.status = props.fileStatus;
          state.type = props.fileType;
          state.path = "https:" + props.filePath;
          if (props.fileType == 1) {
            if (props.documentId) {
              state.path =
                state.path.slice(0, state.path.lastIndexOf(".")) +
                "/1/mp4/1_1.mp4";
            }
          }
          if (props.fileType == 6) {
            state.path = state.path.replace(/.[^/.]+$/, "/" + props.fileHome);
          }
        } else {
          state.type = 0;
        }
      }
    );

    const handleCancel = () => {
      emit("previewEnd", false);
    };

    return {
      ...toRefs(state),
      handleCancel,
    };
  },
});
</script>
<style lang="less">
.preview-full-modal {
  // .ant-modal {
  //   max-width: 90%;
  //   top: 0;
  //   padding-bottom: 0;
  //   margin: 0;
  // }
  // .ant-modal-content {
  //   display: flex;
  //   flex-direction: column;
  //   height: calc(90vh);
  // }
  // .ant-modal-body {
  //   flex: 1;
  // }
  .ant-modal-footer {
    & > div {
      .ant-btn:first-child {
        display: none;
      }
    }
  }
}
</style>
